<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1 class="mb-0">Credit Notes</h1>
      <div class="flex-shrink-0">
        <CreditNotesExport :credit-notes="creditNotes" class="d-inline-block mr-2" />
        <router-link class="btn btn-sm btn-success" to="creditnotes/new">New Credit Note</router-link>
      </div>
    </div>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Name</th>
          <th>Invoice ID</th>
          <th>Date</th>
          <th>Sum</th>
          <th>Doc</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="creditNote in creditNotes" :key="creditNote.id">
          <td class="align-middle">
            {{ creditNote.address.name1 }}<br />
            {{ creditNote.address.name2 }}
          </td>
          <td class="align-middle">
            <span v-if="creditNote.invoiceId">{{ creditNote.invoiceId.replace('_', '/') }}</span>
          </td>
          <td class="align-middle">
            {{ formatDate(creditNote.date.creditNote.toDate()) }}
          </td>
          <td class="align-middle">
            {{ formatCurrency(creditNote.sums.gross.total) }}
            <small>({{ formatCurrency(creditNote.sums.net.total) }})</small>
          </td>
          <td class="align-middle">
            <button
              class="btn btn-sm"
              :class="creditNote.documentGenerated ? 'btn-outline-success' : 'btn-outline-danger'"
              :disabled="!creditNote.documentGenerated"
              @click="openCreditNotePdf(creditNote.id)"
            >
              {{ creditNote.id }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db, storage, env } from '@/shared/firebase'
import CreditNotesExport from '../../components/Invoices/CreditNotesExport.vue'

export default {
  data() {
    return {
      creditNotes: [],
      subscription: null
    }
  },
  created() {
    this.subscription = db
      .collection('creditNotes')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        return (this.creditNotes = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        }))
      })
  },
  beforeDestroy() {
    this.subscription()
  },
  methods: {
    async openCreditNotePdf(creditNoteId) {
      try {
        const bucketName = env === 'production' ? 'emma-billings' : 'emma-billings-dev'
        const creditNoteUrl = await storage
          .refFromURL(`gs://${bucketName}/creditNotes/${creditNoteId}.pdf`)
          .getDownloadURL()
        window.open(creditNoteUrl, '_blank')
      } catch (error) {
        alert(`Couldn't get PDF: ${error.code} - ${error.message}`)
      }
    }
  },
  components: { CreditNotesExport }
}
</script>
