<template>
  <div>
    <button class="btn btn-outline-primary" @click="exportCreditNotes">Export</button>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'

require('moment/locale/de')

export default {
  props: {
    creditNotes: {
      required: true
    }
  },
  methods: {
    async exportCreditNotes() {
      const dateString = prompt('Date String? (e.g. 2023-04)')
      if (!dateString) return
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Invoices')
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'Umsatz', key: 'revenue', width: 10 },
        { header: 'Soll/Haben-Kennzeichen', key: 'debitCredit' },
        { header: 'WKZ Umsatz', key: 'WKZRevenue' },
        { header: 'Kurs', key: 'rate' },
        { header: 'Basis-Umsatz', key: 'basicRevenue' },
        { header: 'WKZ Basis-Umsatz', key: 'WKZBasicRevenue' },
        { header: 'Konto', key: 'account' },
        { header: 'Gegenkonto (ohne BU-Schlüssel) ', key: 'contraAccount' },
        { header: 'BU-Schlüssel', key: 'BU' },
        { header: 'Belegdatum', key: 'invoiceDate', width: 10 },
        { header: 'Belegfeld 1', key: 'invoiceField1', width: 14 },
        { header: 'Belegfeld 2', key: 'invoiceField2' },
        { header: 'Skonto', key: 'discount' },
        { header: 'Buchungstext', key: 'bookingText', width: 50 }
      ]

      this.creditNotes
        .filter(o => moment(o.createdAt.toDate()).format('YYYY-MM') === dateString)
        .forEach(c => {
          const booking = {
            debitCredit: 'H',
            account: this.$store.state.organizations.find(o => o.id === c.organizationId).customerId,
            invoiceDate: moment(c.createdAt.toDate()).format('DMM'),
            invoiceField1: c.id.replace('/', '_'),
            bookingText: c.address.name1
          }
          if (c.sums.gross[7] !== 0) {
            worksheet.addRow({
              ...booking,
              contraAccount: '8300',
              revenue: parseFloat(c.sums.gross[7].toFixed(2))
            })
          }
          if (c.sums.gross[19] !== 0) {
            worksheet.addRow({
              ...booking,
              contraAccount: '8400',
              revenue: parseFloat(c.sums.gross[19].toFixed(2))
            })
          }
        })

      workbook.xlsx.writeBuffer().then(data => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `CreditNotes ${dateString}.xlsx`)
      })
    }
  }
}
</script>
